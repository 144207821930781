/* eslint-disable react/no-danger */
import React, {
  FC, useState, useEffect, createRef,
} from 'react';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import {
  Text, ShadowBlock, Button, Spinner,
} from '@primitives';
import { Thread, MessageForm, MessageBadge } from '@components/Messages';
import styled from 'styled-components';
import { useStores } from '@stores';
import { IMessageStore, IThread } from '../../types';
import { observer } from 'mobx-react';
import { ContentPageLayout } from '@components/Layout';
import { IAuthStore, IMarkdownData } from '../../types/CommonTypes';
import { graphql } from 'gatsby';
import { TextSection } from '@components/TextSection';


const NewMessageButton = styled(Button)`
  margin: auto;
  box-shadow: ${(props) => props.theme.boxShadow.basic};
  padding: 1rem;
  margin-bottom: 4rem;
`;

const ThreadList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type:none;
`;

interface IMessagesIndex {
  data: IMarkdownData;
}

const MessagesIndexPage: FC<IMessagesIndex> = observer(({
  data: { markdownRemark: { frontmatter: { blocks: texts } } },
}) => {
  const {
    messageStore: { getThreads, threads, markThreadRead, state },
    authStore: { isAuthenticated },
  }: {
    messageStore: IMessageStore;
    authStore: IAuthStore;
  } = useStores();
  const [openThread, setOpenThread] = useState('');
  const [newMessageFormOpen, setNewMessageFormOpen] = useState(false);
  const container = createRef<HTMLDivElement>();

  const delay = async (duration: number) => new Promise((resolve) => setTimeout(resolve, duration));

  const isLoading = state === 'Loading';

  // TODO: remove at some point
  useEffect(() => {
    if (isAuthenticated) {
      getThreads();
    }
  }, [isAuthenticated]);

  const onThreadClick = async (id: IThread['thread_id'], status: IThread['status']) => {
    if (id === openThread) {
      setOpenThread('');
      return;
    }
    setOpenThread(id);
    if (status === 'unread') markThreadRead(id);
  };

  const onNewMessageClick = async () => {
    setNewMessageFormOpen(true);
    setOpenThread('');

    if (container.current) {
      const immutable = Object.freeze({ ref: container.current });
      await delay(250);

      immutable.ref.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  };

  const onCancelNewMessage = () => {
    setNewMessageFormOpen(false);
  };

  return (
    <ContentPageLayout>
      <div ref={container}>
        <TextSection title={texts[0].title} text={texts[0].text}></TextSection>
        <NewMessageButton
          onClick={onNewMessageClick}
          id="create-new-message"
          background="white"
          color="textBlue"
          mb="3"
        >
          <MessageBadge />
          <Text ml="1">
            <M id="pages.messages.sendNewMessage" />
          </Text>
        </NewMessageButton>
        {newMessageFormOpen && (
          <ShadowBlock mt="2" mb="1">
            <MessageForm
              onCancel={onCancelNewMessage}
              instructions={texts.find((text) => text.id === 'newMessageInstructions')?.text}
            />
          </ShadowBlock>
        )}
      </div>
      {isLoading && <Spinner color="mainBlue" />}
      <ThreadList>
        {threads && threads.map((thread: any) => {
          return (
            <li key={thread.thread_id}>
              <Thread
                open={openThread === thread.thread_id}
                messages={thread.messages}
                onClick={onThreadClick}
                {...thread}
              />
            </li>
          );
        })}
      </ThreadList>
    </ContentPageLayout>
  );
});

export const pageQuery = graphql`
query MessagesQuery {
  markdownRemark(frontmatter: {path: {eq: "/messages/"}}) {
    frontmatter {
      path
      blocks {
        title
        text
        id
      }
    }
    htmlAst
  }
}`;

export default MessagesIndexPage;
